export const indianStates = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Lakshadweep",
    "Delhi",
    "Puducherry"
  ];

  export const EMPLOYMENT = [
    "AIR FORCE",
    "BUSINESS PERSON",
    "CAMERAMAN",
    "CHARITY/SOCIAL WORKER",
    "CHARTED ACCOUNTANT",
    'COLLEGE/UNIVERSITY TEACHER',
    "DIPLOMAT",
    "DOCTOR",
    "ENGINEER",
    "FILM PRODUCER",
    "GOVERNMENT SERVICE",
    "HOUSE WIFE",
    "JOURNALIST",
    "LABOUR",
    "LAWYER",
    "MEDIA",
    "MILITARY",
    "NAVY",
    "NEWS BROADCASTER",
    "OFFICIAL",
    "OTHERS",
    "POLICE",
    "PRESS",
    "PRIVATE SERVICE",
    "PUBLISHER",
    "REPORTER",
    "RESEARCHER",
    "RETIRED",
    "SEA MAN",
    "SELF EMPLOYED/ FREELANCER",
    "STUDENT",
    "TRADER",
    "TV PRODUCER",
    "UN-EMPLOYED",
    "UN OFFICIAL",
    "WORKER",
    "WRITER"

  ]
  
 